import cn from "classnames"
import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { Heading } from "~/components/Heading/Heading"
import { policyFileName } from "~/components/PolicyFile/PolicyFile"
import { Raw } from "~/components/raw"
import { SocialLinks } from "~/components/Social/SocialLinks/SocialLinks"
import { Subscription } from "~/components/Subscription/Subscription"

import styles from "./index.module.css"

export const Bottom: React.FC = () => {
  const { t } = useTranslation()
  const currLang = i18next.language

  return (
    <div className={styles.Bottom}>
      <div className={styles.Block}>
        <Heading as="div" className={styles.Title} like="h4">
          <Raw html={t("footer.Address.Title")} />
        </Heading>
        <div dangerouslySetInnerHTML={{ __html: t("footer.Address.Content") }} />
        <div className={cn(styles.Copyright, "d-none d-md-block")}>
          <Copyright />
        </div>
      </div>

      <div className={styles.Block}>
        <Heading as="div" className={styles.Title} like="h4">
          <Raw html={t("footer.Helpdesk.Title")} />
        </Heading>
        <div>
          <a href="tel:+74957370101">+7 (495) 737-01-01</a>
        </div>
        {/*<SocialLinks axis="x" className={cn(styles.Social, "d-none d-sm-block")} />*/}
      </div>

      {/*<div className={styles.Block}>*/}
      {/*  <Heading as="div" className={styles.Title} like="h4">*/}
      {/*    <Raw html={t("footer.Subscribe.Title")} />*/}
      {/*  </Heading>*/}

      {/*  <div className={styles.Subscription}>*/}
      {/*    <Subscription*/}
      {/*      buttonView="xs"*/}
      {/*      classNameButton={styles.SubscriptionButton}*/}
      {/*      classNameInput={styles.SubscriptionInput}*/}
      {/*      code={`${currLang === "ru" ? `["press", "News"]` : `["press_en", "IR"]`}`}*/}
      {/*      returnValuesType="code"*/}
      {/*    />*/}

      {/*    <SocialLinks axis="x" className={cn(styles.Social, "d-sm-none")} />*/}

      {/*    <div className={cn(styles.Copyright, "d-none d-sm-block d-md-none")}>*/}
      {/*      <Copyright />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={styles.Block}>
        <div className={styles.Links}>
          <div className={styles.Link}>
            <a href={`/upload/${policyFileName()}`} rel="noopener noreferrer" target="_blank">
              <Raw html={t("footer.Policy.Title")} />
            </a>
          </div>
          <div className={styles.Link}>
            <a href="mailto:kolchugin@sistema.ru,reshetnyak@sistema.ru,afknoreply@dabb.ru">
              <Raw html={t("footer.Bugreport.Title")} />
            </a>
          </div>
        </div>
      </div>

      <div className={cn(styles.Copyright, "d-sm-none")}>
        <Copyright />
      </div>
    </div>
  )
}

const Copyright: React.FC = () => {
  const currentYear = new Date().getFullYear()

  const { t } = useTranslation()

  return (
    <div>
      &copy;&nbsp;1993&nbsp;&ndash;&nbsp;{currentYear} <Raw html={t("footer.Copyright.Title")} />
    </div>
  )
}
