/* eslint-disable complexity */
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers, FormikValues } from "formik"
import React from "react"

import { getApiUrl, useApiHostContext } from "~/api"
import { postFetch } from "~/helpers/usePostFetch"

import { usePopup } from "../Popup/Popup"
import { SuccessPopup } from "./components/SuccessPopup/SuccessPopup"

type Props = {
  url: string
  initialValues: FormikValues
  className?: string
  isFileRequired?: boolean
  showSuccess?: boolean
  successMessage?: { title: string; message: string }
  excludeValidate?: string[]
}

const validate = (
  values: FormikValues,
  isFileRequired?: boolean,
  excludeValidate?: string[],
): FormikErrors<FormikValues> => {
  const errors: FormikErrors<FormikValues> = {}

  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      const value = values[key]

      if (key === "files") {
        if (isFileRequired && value.length === 0) {
          errors[key] = "Required"
        }

        continue
      }

      if (!excludeValidate?.includes(key)) {
        if (value.length === 0) {
          errors[key] = "Required"
        }
      }

      if (/\b(\w*email\w*)\b/.test(key)) {
        if (value && !/^[\w%+-.]+@[\d-.a-z]+\.[a-z]{2,4}$/i.test(value)) {
          errors[key] = "Invalid email address"
        }
      }
    }
  }

  return errors
}

export const Form: React.FC<Props> = ({
  url,
  children,
  initialValues,
  className,
  isFileRequired,
  showSuccess = true,
  successMessage,
  excludeValidate,
}) => {
  const apiHost = useApiHostContext()
  const urlString = getApiUrl(apiHost, url)
  const { openPopup, closePopup, Popup } = usePopup(<SuccessPopup message={successMessage} />)

  const submitHandler = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ): Promise<any> => {
    const formData = new FormData()

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key]

        // проверяем данные на наличие прикреплённых файлов
        // и обрабатываем отдельно
        try {
          value.forEach((v: any) => {
            // сделать `${key}[]` вместо key, если будем отправлять массив файлов
            formData.append(key, v)
          })
        } catch (error_) {
          formData.append(key, value)
        }
      }
    }

    const { error } = await postFetch(urlString, {
      method: "POST",
      body: formData,
    })

    if (!error) {
      formikHelpers.resetForm()

      if (showSuccess) {
        openPopup()
      }
    }

    if (error) {
      formikHelpers.setStatus({ isServerError: true })
      setTimeout(() => {
        formikHelpers.setStatus({ isServerError: false })
      }, 2000)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={(values) => validate(values, isFileRequired, excludeValidate)}
        onSubmit={submitHandler}
      >
        <FormikForm className={className}>{children}</FormikForm>
      </Formik>
      <Popup />
    </>
  )
}
