import cn from "classnames"
import React from "react"

import { useThemeContext } from "~/components/Theme/Theme"

import styles from "./index.module.css"

export type HeadingProps = {
  like: "h1" | "h2" | "h3" | "h4" | "h5"
  as?: React.ElementType<any>
  className?: string
  id?: string
  href?: string
  onClick?: (...props: any) => void
}

export const Heading: React.FC<HeadingProps> = ({
  like,
  as: Component = like,
  children,
  className,
  id,
  href,
  onClick,
}) => {
  const { theme } = useThemeContext()

  const clsName = cn(className, {
    [styles.H1]: like === "h1",
    [styles.H2]: like === "h2",
    [styles.H3]: like === "h3",
    [styles.H4]: like === "h4",
    [styles.H5]: like === "h5",
    [styles.OnDark]: theme === "dark",
  })

  return (
    <Component className={clsName} href={href} id={id} onClick={onClick}>
      {children}
    </Component>
  )
}
