import fetch from "isomorphic-unfetch"
import { NextPageContext } from "next"
import { createContext, useContext } from "react"

import { Crumb } from "~/components/Breadcrumbs/BreadcrumbsProvider"
import { getQueryParams } from "~/helpers/getQueryParams"
import { detectLang } from "~/i18n"

import { ApiContextType, ApiContextUrl } from "./types"

export type ResponseType<T extends ApiContextUrl> = {
  [K in T]: {
    url?: T
    request: RequestType
    response: ApiContextType[K]
  }
}

type ContextType = {
  apiData: ResponseType<ApiContextUrl>
  slug?: string
  apiHost: string
}

const ApiContext = createContext<ContextType>({
  apiData: {} as any,
  apiHost: "",
})

export type RequestType = {
  url: Partial<ApiContextUrl>
  code?: string
  query?: any
  urlName?: string
}

type GetApiContextType = <T extends ApiContextUrl>(
  requests: RequestType[],
  host: string | undefined,
) => Promise<ResponseType<T>>

export type GetInitialPropsType = (
  context: NextPageContext,
) => Promise<{
  apiContext?: ResponseType<ApiContextUrl>
  crumbs?: Crumb[]
  slug?: string
}>

export const ApiContextProvider = ApiContext.Provider

export const getApiHost = (host: string): string => {
  const regexpIpWithPort = /:\d+$/
  const lang = detectLang(host)
  const domain = lang === "ru" ? "ru" : "com"
  const dev =
    host.includes("localhost:") ||
    regexpIpWithPort.test(host) ||
    host.includes(`webtest.sistema.${domain}`)

  if (process.env.API_ENDPOINT) {
    return process.env.API_ENDPOINT
  }

  return dev ? `https://webtest.sistema.${domain}/api` : `https://${host}/api`
}

export const getApiUrl = (host: string, url: string, query?: any): string => {
  const queryString = getQueryParams(query)
  return `${host}${url}?${queryString}`
}

const normalizeQuery = (query: any): any => {
  //  преобразуем все ключи с постфиксом "_arr" в массив
  const normalizedQuery: Record<string, any> = {}
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      const element = query[key]
      normalizedQuery[key] = key.includes("_arr") ? element.split(",") : element
    }
  }

  return normalizedQuery
}

/**
 * Возвращает дополнительные заголовки для запросов на внешнее API.
 * В настоящее время используется только для проброса куки-триггера для xDebug.
 *
 * @param {string|undefined} mode
 */
export const getRequestHeaders = (mode?: string): Record<string, string> => {
  const defaults = {}

  if (!mode) {
    return defaults
  }

  const map: Record<string, Record<string, string>> = {
    local: {
      Cookie: "XDEBUG_SESSION=1",
    },
  }

  return map[mode] || defaults
}

export const getApiContext: GetApiContextType = async (requests, host) => {
  const context: any = {} as any
  const apiHost = getApiHost(host as string)
  for (const request of requests) {
    const { url, code, query, urlName } = request

    try {
      const response = await (
        await fetch(
          getApiUrl(apiHost, url, {
            ...query,
            code,
          }),
          {
            headers: getRequestHeaders(process.env.APP_ENV),
          },
        )
      ).json()
      const normalizedQuery = normalizeQuery(query)
      context[urlName || url] = { response, request: { ...request, query: normalizedQuery } }
    } catch (error) {
      console.error(error)
    }
  }

  return context
}

export const useApiContext = <T extends ApiContextUrl>(url: T): ApiContextType[T] => {
  const context = useContext(ApiContext)

  if (!context.apiData) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return []
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return context.apiData[url]?.response || []
}

export const useApiContextRequest = <T extends ApiContextUrl>(url: T): RequestType => {
  const context = useContext(ApiContext)
  return context.apiData[url].request || []
}

export const useApiContextSlug = (): string | undefined => {
  const context = useContext(ApiContext)
  return context.slug
}

export const useApiHostContext = (): string => {
  const context = useContext(ApiContext)
  return context.apiHost
}
