import React from "react"

import { Theme } from "~/components/Theme/Theme"

import styles from "./index.module.css"

export const LayoutBg: React.FC = () => {
  return (
    <Theme className={styles.Wrapper} theme="dark">
      <div />
    </Theme>
  )
}
