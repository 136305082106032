import cn from "classnames"
import React from "react"

import styles from "./index.module.css"

type Props = {
  onClick?: () => void
  isOpen?: boolean
  icon?: "plus" | "arrow" | "calendar"
  className?: string
  iconComponent?: () => React.ReactNode
}

export const ShadowField: React.FC<Props> = ({
  children,
  onClick,
  className,
  isOpen,
  icon = "arrow",
  iconComponent,
}) => (
  <div
    className={cn(className, styles.Field)}
    data-state={isOpen ? "open" : undefined}
    onClick={onClick}
  >
    {children}
    {iconComponent ? (
      <div className={cn(styles.Icon)}>{iconComponent()}</div>
    ) : (
      <div
        className={cn(styles.Icon, {
          [styles.Arrow]: icon === "arrow",
          [styles.Plus]: icon === "plus",
          [styles.Calendar]: icon === "calendar",
        })}
      />
    )}
  </div>
)
