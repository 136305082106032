import cn from "classnames"
import { Field, FieldProps } from "formik"
import React, { ChangeEvent } from "react"

import styles from "./index.module.css"

type InputProps = JSX.IntrinsicElements["textarea"] & {
  label: string
  active?: boolean
  error?: boolean
  isActive?: boolean
  onChange: (e: ChangeEvent<any>) => void
}

export const TextArea: React.FC<InputProps> = ({
  className,
  label,
  error,
  value,
  onChange,
  ...properties
}) => (
  <div className={cn(styles.Wrapper, className)}>
    <textarea
      className={cn({
        [styles._active]: !!value,
        [styles._error]: error,
      })}
      id={label}
      value={value}
      onChange={onChange}
      {...properties}
    />

    <label>{label}</label>
  </div>
)

type FormTextArea = {
  label: string
  active?: boolean
  className?: string
  name: string
}

export const FormTextArea: React.FC<FormTextArea> = ({ className, label, name, ...properties }) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <TextArea
        className={className}
        error={!!meta.error && meta.touched}
        label={label}
        value={meta.value}
        {...field}
        {...properties}
      />
    )}
  </Field>
)
