import cn from "classnames"
import i18next from "i18next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { FadeTransition } from "~/components/Transitions/Transitions"
import { useFixedPosition } from "~/helpers/useFixedPosition"
import { useHeadroom } from "~/helpers/useHeadroom"
import { useLangLocation } from "~/helpers/useLangLocation"
import { useSetState } from "~/helpers/useSetState"

import { CheckVisibility } from "../CheckVisibility/CheckVisibility"
import { Promo } from "../Promo/Promo"
import imgLogoDarkEn from "./assets/logo-dark-en.svg"
import imgLogoDarkRu from "./assets/logo-dark-ru.svg"
import imgLogoLightEn from "./assets/logo-light-en.svg"
import imgLogoLightRu from "./assets/logo-light-ru.svg"
import PhoneSvg from "./assets/phone.react.svg"
import { Menu } from "./components/Menu/Menu"
import { Navigation } from "./components/Navigation/Navigation"
import styles from "./index.module.css"

export type HeaderType = {
  theme?: "onLight" | "onDark"
  isHome?: boolean
}

type HeaderState = {
  menuIsOpen: boolean
  currentTheme: HeaderType["theme"]
  state: string
  lastDirection: string
}

export const Header: React.FC<HeaderType> = ({ theme = "onLight", isHome }) => {
  const { direction, isOnTop } = useHeadroom()
  const currentLang = i18next.language

  const [{ menuIsOpen, currentTheme, state, lastDirection }, setState] = useSetState<HeaderState>({
    menuIsOpen: false,
    currentTheme: theme,
    state: "top",
    lastDirection: "",
  })

  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const closeMenu = (): void => {
    setState({
      menuIsOpen: false,
      currentTheme: state === "top" ? theme : "onDark",
      state: lastDirection,
    })
    setIsSearchOpen(false)
  }

  const openMenu = (): void => {
    setState({ menuIsOpen: true, currentTheme: "onDark", lastDirection: state })
  }

  const openSearch = (): void => {
    openMenu()
    setIsSearchOpen(true)
  }

  const toggleMenu = (): void => {
    if (menuIsOpen) {
      closeMenu()
      return
    }

    openMenu()
  }

  useEffect(() => {
    if (menuIsOpen) {
      return
    }

    if (lastDirection) {
      setState({ lastDirection: "" })
      return
    }

    setState({
      state: isOnTop ? "top" : direction,
      currentTheme: isOnTop ? theme : "onDark",
    })
  }, [isOnTop, direction])

  useFixedPosition(closeMenu, menuIsOpen)

  return (
    <header
      className={styles.Wrapper}
      data-direction-state={state}
      data-js="header"
      data-state={menuIsOpen ? "open" : undefined}
      data-theme={currentTheme}
    >
      <CheckVisibility>
        {() => (
          <div className={cn(styles.Inner, { [styles.InHome]: isHome })}>
            <div className={styles.Top} data-theme={currentTheme}>
              <FadeTransition isVisible={!menuIsOpen} unmountOnExit={false}>
                <div className={styles.LogoItems}>
                  {/* TODO: Определять находимся мы на главной странице или нет */}
                  <a className={styles.Logo} href="/">
                    {currentLang === "ru" && (
                      <img src={currentTheme === "onLight" ? imgLogoLightRu : imgLogoDarkRu} />
                    )}

                    {currentLang === "en" && (
                      <img src={currentTheme === "onLight" ? imgLogoLightEn : imgLogoDarkEn} />
                    )}
                  </a>
                </div>
              </FadeTransition>

              <div className={styles.TopItems}>
                <div className={styles.Phone}>
                  <a href="tel:+74957370101">+7 (495) 737-01-01</a>
                </div>
                <Lang />
                <div className={styles.PhoneWithIcon}>
                  <a href="tel:+74957370101">
                    <PhoneSvg />
                  </a>
                </div>
              </div>
              <div className={styles.Burger} onClick={toggleMenu}>
                <span />
              </div>
            </div>

            <Navigation
              className={styles.Navigation}
              theme={currentTheme}
              onSearchClick={openSearch}
            />
          </div>
        )}
      </CheckVisibility>
      <Menu isOpen={menuIsOpen} isSearchOpen={isSearchOpen} />
    </header>
  )
}

const Lang: React.FC = () => {
  const { t, i18n } = useTranslation()

  const { oppositeHref } = useLangLocation(i18n.language)

  return (
    <div className={styles.Lang}>
      <a href={oppositeHref}>{t("header.lang")}</a>
    </div>
  )
}
