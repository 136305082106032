import React from "react"
import { useTranslation } from "react-i18next"

import { Raw } from "~/components/raw"

import { TabWrapper } from "../TabWrapper/TabWrapper"

export const SendMail: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TabWrapper title={t("contacts.PopupHotLine.SendMail.Title")}>
      <Raw html={t("contacts.PopupHotLine.SendMail.Description")} />
    </TabWrapper>
  )
}
