import cn from "classnames"
import { Field, FieldProps } from "formik"
import React, { useEffect, useRef, useState } from "react"
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore
import Files from "react-files"
import { useTranslation } from "react-i18next"

import { Raw } from "../raw"
import { useThemeContext } from "../Theme/Theme"
import styles from "./index.module.css"

type FileType = {
  id: string
  name: string
}

type FilesType = FileType & {
  removeFile: (file: FileType) => void
  removeFiles: () => void
}

type ErrorType = {
  code: 1 | 2 | 3 | 4
  message: string
}

type AttachFileProps = {
  onChange: (files: any) => void
  name: string
  value: any
  error?: boolean
} & AttachFileFieldProps

export const AttachFile: React.FC<AttachFileProps> = ({
  className,
  name,
  onChange,
  error,
  value,
  onlyPdf,
}) => {
  const { t } = useTranslation()
  const { theme } = useThemeContext()
  const [uploadedFiles, setFiles] = useState<FilesType[]>([])
  const [errorMessages, setErrors] = useState<string[]>([])
  const inputEl = useRef<FilesType>(null)
  const onFilesChange = (files: FilesType[]): void => {
    onChange(files)
    setFiles(files)
  }

  const onFilesError = (error: ErrorType, file: FileType): void => {
    if (error.code === 2) {
      setErrors((prevState) => [
        ...prevState,
        `${file.name} ${t("popup.Default.Form.AttachFile.Errors.Size")}`,
      ])
    }
    // console.log("error code " + error.code + ": " + error.message)
  }

  const filesRemoveOne = (file: FileType): void => {
    setErrors([])
    inputEl.current?.removeFile(file)
  }

  const ResetErrors = (): void => {
    setErrors([])
  }

  useEffect(() => {
    if (!value) {
      inputEl.current?.removeFiles()
    }
  }, [value])

  return (
    <div className={cn(className, styles.Wrapper)}>
      <div className={styles.AddButtonWrapper}>
        <Files
          ref={inputEl}
          accepts={
            !onlyPdf
              ? [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".png"]
              : [".pdf"]
          }
          className={cn(styles.AddButton, {
            [styles._error]: error,
          })}
          maxFileSize={8388608}
          multiple={false}
          name={name}
          clickable
          onChange={onFilesChange}
          onError={onFilesError}
        >
          <div
            dangerouslySetInnerHTML={{ __html: t("popup.Default.Form.AttachFile.AddButton") }}
            onClick={ResetErrors}
          />
        </Files>

        <div
          className={styles.Notice}
          dangerouslySetInnerHTML={{
            __html: !onlyPdf
              ? t("popup.Default.Form.AttachFile.Notice")
              : t("popup.Default.Form.AttachFile.Notice.Pdf"),
          }}
        />
      </div>

      {uploadedFiles.length > 0 && (
        <div className={styles.Files}>
          {uploadedFiles.map((file) => (
            <div
              key={file.id}
              className={cn({
                [styles.File]: theme !== "light-blue",
                [styles.onLightBlue]: theme === "light-blue",
              })}
            >
              <Raw html={file.name} />
              <div
                className={styles.DeleteFile}
                id={file.id}
                onClick={() => filesRemoveOne(file)}
              />
            </div>
          ))}
        </div>
      )}

      {errorMessages.length !== 0 && (
        <div className={styles.Errors}>
          {errorMessages.map((message, i) => (
            <div key={i} className={styles.Error}>
              {message}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type AttachFileFieldProps = {
  className?: string
  name?: string
  onlyPdf?: boolean
}

export const AttachFileField: React.FC<AttachFileFieldProps> = ({
  className,
  name = "files",
  onlyPdf,
}) => (
  <Field name={name}>
    {({ field, form: { setFieldValue }, meta }: FieldProps) => {
      const onChange = (files: any): void => {
        setFieldValue(name, files)
      }

      return (
        <AttachFile
          className={className}
          error={!!meta.error && meta.touched}
          {...field}
          name={name}
          onlyPdf={onlyPdf}
          onChange={onChange}
        />
      )
    }}
  </Field>
)
