import cn from "classnames"
import React from "react"

import { useThemeContext } from "~/components/Theme/Theme"

import styles from "./index.module.css"

type CommonProps = {
  view: "arrow" | "mail" | "dropdown"
  as?: "a" | "div"
}

export const Link: React.FC<JSX.IntrinsicElements["a"] &
  JSX.IntrinsicElements["div"] &
  CommonProps> = ({ children, className, view, as: Component = "a", ...props }) => {
  const { theme } = useThemeContext()
  const clsName = cn(className, {
    [styles.Light]: theme === "light",
    [styles.Dark]: theme === "dark",
    [styles.Arrow]: view === "arrow",
    [styles.Mail]: view === "mail",
    [styles.Dropdown]: view === "dropdown",
  })

  return (
    <Component {...props} className={clsName}>
      <span>{children}&nbsp;</span>
    </Component>
  )
}
