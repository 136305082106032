import cn from "classnames"
import React, { useRef, useState } from "react"

import { Portal } from "~/components/Portal/Portal"
import { useFixedPosition } from "~/helpers/useFixedPosition"
import useOutsideClick from "~/helpers/useOutsideClick"

import { Theme } from "../Theme/Theme"
import { FadeTransition } from "../Transitions/Transitions"
import styles from "./index.module.css"

type PopupContentProps = {
  button?: React.ReactElement
  content(props: { openHandler: () => void; closeHandler: () => void }): JSX.Element
  onOpen?: () => void
  className?: string
}

export const usePopup = (children: React.ReactChild): any => {
  const [isOpen, setOpen] = useState(false)
  const closePopup = (): void => setOpen(false)
  const openPopup = (): void => setOpen(true)

  const PopupComponent: React.FC = () => (
    <FadeTransition isVisible={isOpen}>
      <Popup closeHandler={closePopup}>{children}</Popup>
    </FadeTransition>
  )

  return { openPopup, closePopup, Popup: PopupComponent }
}

export const PopupWrapper: React.FC<PopupContentProps> = ({
  content,
  button,
  onOpen,
  className,
}) => {
  const [isOpen, openPopup] = useState(false)
  const closeHandler = (): void => openPopup(false)
  const openHandler = (): void => openPopup(true)

  return (
    <>
      {React.cloneElement(button as React.ReactElement, {
        onClick: () => {
          if (onOpen) {
            onOpen()
          }

          openHandler()
        },
      })}

      <FadeTransition isVisible={isOpen}>
        <Popup className={className} closeHandler={closeHandler}>
          {content({ closeHandler, openHandler })}
        </Popup>
      </FadeTransition>
    </>
  )
}

type PopupProps = {
  closeHandler: () => void
  className?: string
}

const Popup: React.FC<PopupProps> = ({ children, closeHandler, className }) => {
  const ref = useRef(null)

  useFixedPosition(closeHandler)
  useOutsideClick(ref, closeHandler)
  // const onPopState = (): void => {
  //   closeHandler()
  //   history.forward()
  // }

  // useEffect(() => {
  //   const { pathname, search } = location
  //   const delimiter = search ? "&" : "?"
  //   history.pushState(null, "", `${pathname}${search}${delimiter}p`)
  //   window.addEventListener("popstate", onPopState)

  //   return () => {
  //     history.back()
  //     window.removeEventListener("popstate", onPopState)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <Portal>
      <div className={styles.Wrapper}>
        <div className={styles.Inner}>
          <div
            ref={ref}
            className={cn(className, styles.ScrollableArea, {
              [styles.ScrollableAreaDefault]: !className,
            })}
          >
            <div className={styles.ContentWrapper}>
              <div className={styles.Close} onClick={closeHandler} />
              <div className={styles.Content}>
                <Theme theme="light">{children}</Theme>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
