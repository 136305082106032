import React from "react"
import { useTranslation } from "react-i18next"

import { AttachFileField } from "~/components/AttachFile/AttachFile"
import { FormButton } from "~/components/Button/Button"
import { Form } from "~/components/Form/Form"
import { FormTextArea } from "~/components/TextArea/TextArea"

import { TabWrapper } from "../TabWrapper/TabWrapper"
import styles from "./index.module.css"

export const Feedback: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TabWrapper childrenForm>
      <Form
        className={styles.Form}
        initialValues={{
          message: "",
        }}
        url="/hotline/submit"
      >
        <FormTextArea label={t("popup.Default.Form.Message.Label")} name="message" />
        <AttachFileField />

        <FormButton className={styles.Button} view="xl">
          {t("popup.Default.Form.SendButton")}
        </FormButton>
      </Form>
    </TabWrapper>
  )
}
