import cn from "classnames"
import { Field, FieldProps } from "formik"
import React from "react"

import { useThemeContext } from "~/components/Theme/Theme"

import styles from "./index.module.css"

type ViewType = "xs" | "xl" | "rounded" | "outline"
type CommonProps = { view: ViewType }
type ButtonProps = JSX.IntrinsicElements["button"] & CommonProps
type LinkButtonProps = JSX.IntrinsicElements["a"] & CommonProps

const useClassName = (view: ViewType): string => {
  const { theme } = useThemeContext()
  const className = cn({
    [styles.Xs]: view === "xs",
    [styles.Xl]: view === "xl",
    [styles.Rounded]: view === "rounded",
    [styles.Outline]: view === "outline",
    [styles.Shadow]: view !== "rounded" && view !== "outline",
    [styles.OnDark]: theme === "dark",
    [styles.OnLight]: theme === "light",
  })

  return className
}

export const Button: React.FC<ButtonProps> = ({ children, className, view, ...props }) => (
  <button {...props} className={cn(className, useClassName(view))}>
    {children}
  </button>
)

export const ButtonLink: React.FC<LinkButtonProps> = ({ children, className, view, ...props }) => (
  <a {...props} className={cn(className, useClassName(view))}>
    {children}
  </a>
)

export const FormButton: React.FC<ButtonProps> = ({ className, children, view, ...props }) => (
  <Field>
    {({ form }: FieldProps) => (
      <Button
        className={cn(className, {
          [styles.IsLoading]: form.isSubmitting,
          [styles.IsError]: form.status?.isServerError,
        })}
        // error={!!meta.error && meta.touched}
        view={view}
        {...props}
        type="submit"
      >
        {children}
      </Button>
    )}
  </Field>
)
