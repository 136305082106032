import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

export const policyFileName = (): string => {
  const currentLang = i18next.language

  return currentLang === "en" ? "2_9politika_pdn__eng.pdf" : "2_9politika_pdn__.pdf"
}

export const PolicyMessage: React.FC<{ className?: string; buttonName?: string }> = ({
  className,
  buttonName,
}) => {
  const fileName = policyFileName()
  const { t } = useTranslation()

  return (
    <div
      dangerouslySetInnerHTML={{ __html: t("PolicyMessage", { buttonName, fileName }) }}
      className={className}
    />
  )
}
