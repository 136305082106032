import { useEffect, useRef } from "react"

import { useSetState } from "./useSetState"

type HeadRoomState = { direction: "down" | "up"; isOnTop: boolean }

export const useHeadroom = (): HeadRoomState => {
  const scrollDistanceRef = useRef(0)
  const scrollIsOnTop = useRef(true)

  const [{ direction, isOnTop }, setState] = useSetState<HeadRoomState>({
    direction: "down",
    isOnTop: true,
  })

  const offset = 200

  const scroll = (): void => {
    const scrollDirection = scrollDistanceRef.current < window.scrollY ? "down" : "up"
    scrollDistanceRef.current = window.scrollY

    if (scrollDistanceRef.current <= 0 && scrollDirection === "up") {
      scrollIsOnTop.current = true
    }

    if (scrollDistanceRef.current > offset) {
      scrollIsOnTop.current = false
    }

    if (scrollDirection === direction && scrollIsOnTop.current === isOnTop) {
      return
    }

    setState({ direction: scrollDirection, isOnTop: scrollIsOnTop.current })
  }

  useEffect(() => {
    window.addEventListener("scroll", scroll)

    return () => window.removeEventListener("scroll", scroll)
  }, [])

  return { direction, isOnTop }
}
