import cn from "classnames"
import React from "react"

import { Card } from "~/components/Card/Card"
import { Heading } from "~/components/Heading/Heading"
import { Raw } from "~/components/raw"

import styles from "./index.module.css"

type Props = {
  title?: string
  childrenForm?: boolean
}

export const TabWrapper: React.FC<Props> = ({ children, title, childrenForm }) => {
  return (
    <Card
      className={cn({
        [styles.Wrapper]: !childrenForm,
        [styles.WrapperChilderForm]: childrenForm,
      })}
    >
      {title && (
        <Heading className={styles.Title} like="h4">
          <Raw html={title} />
        </Heading>
      )}
      {children}
    </Card>
  )
}
