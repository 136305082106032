import cn from "classnames"
import { Field, FieldProps } from "formik"
import React, { ReactNode, useEffect, useRef, useState } from "react"

import useOutsideClick from "~/helpers/useOutsideClick"

import { Popover } from "../Popover/Popover"
import { ShadowField } from "../ShadowField/ShadowField"
import styles from "./index.module.css"

type Option = {
  value: string
  label: ReactNode
}

type SelectProps = {
  className?: string
  placeholder?: ReactNode
  error?: boolean

  onChange?: (newValue: Option) => void
  options: Option[]
  renderSelectedLabel?: (option: Option) => ReactNode
  value?: any
}

const defaultRenderSelectedLabel = (option: Option): ReactNode => option.label

export const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  className,
  error,
  placeholder,
  renderSelectedLabel = defaultRenderSelectedLabel,
  value,
}) => {
  const [isOpen, openDropdown] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option | undefined>()
  const wrapper = useRef(null)

  const closeHandler = (): void => openDropdown(false)
  const toggleHandler = (): void => openDropdown(isOpen ? false : true)

  const optionClickHandler = (option: Option): void => {
    setSelectedOption(option)
    if (onChange) {
      onChange(option)
    }
    closeHandler()
  }

  useOutsideClick(wrapper, closeHandler)

  useEffect(() => {
    if (!value) {
      setSelectedOption(undefined)
    }
  }, [value])

  return (
    <div ref={wrapper} className={cn(className, styles.Wrapper)}>
      <ShadowField
        className={cn(styles.Field, {
          [styles.FieldError]: error,
        })}
        isOpen={isOpen}
        onClick={toggleHandler}
      >
        <div>{selectedOption ? renderSelectedLabel(selectedOption) : placeholder}</div>
      </ShadowField>

      <Popover isOpen={isOpen}>
        <div className={styles.Label}>{placeholder}</div>

        {options.map((option) => (
          <div
            key={option.value}
            className={styles.Item}
            onClick={() => optionClickHandler(option)}
          >
            {option.label}
          </div>
        ))}
      </Popover>
    </div>
  )
}

type FormSelectProps = SelectProps & {
  name: string
}

export const FormSelect: React.FC<FormSelectProps> = ({ name, onChange, ...props }) => (
  <Field name={name}>
    {({ field, meta, form: { setFieldValue } }: FieldProps) => {
      const onValueChange = (newValue: Option): void => {
        setFieldValue(field.name, newValue.value)

        if (onChange) {
          onChange(newValue)
        }
      }

      return (
        <Select
          {...field}
          error={!!meta.error && meta.touched}
          {...props}
          onChange={onValueChange}
        />
      )
    }}
  </Field>
)
