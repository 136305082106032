import React from "react"
import { useInView } from "react-intersection-observer"

type Props = {
  threshold?: number | number[]
  triggerOnce?: boolean
  children(slide: { inView: boolean }): JSX.Element
}

export const CheckVisibility: React.FC<Props> = ({
  children,
  threshold = 0.1,
  triggerOnce = true,
}) => {
  const [ref, inView] = useInView({
    threshold,
    triggerOnce,
  })

  const extendedChildren = React.Children.map(children({ inView }) as React.ReactElement, (child) =>
    React.cloneElement(child, { "data-state": inView ? "visible" : undefined, ref }),
  )

  return <>{extendedChildren}</>
}
