import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { Heading } from "~/components/Heading/Heading"
import { PopupWrapper } from "~/components/Popup/Popup"
import { Raw } from "~/components/raw"
import { Tab, Tabs } from "~/components/Tabs/Tabs"

import { CallOnPhone } from "./components/CallOnPhone/CallOnPhone"
import { Feedback } from "./components/Feedback/Feedback"
import { SendEmail } from "./components/SendEmail/SendEmail"
import { SendMail } from "./components/SendMail/SendMail"
import styles from "./index.module.css"

export const Content: React.FC = () => {
  const { t } = useTranslation()

  const selectOptions = [
    t("contacts.PopupHotLine.SelectOption.01"),
    t("contacts.PopupHotLine.SelectOption.02"),
    t("contacts.PopupHotLine.SelectOption.03"),
    t("contacts.PopupHotLine.SelectOption.04"),
  ]

  const currentLang = i18next.language
  const fileName =
    currentLang === "en"
      ? "newpolozhenie_o_gorjachei_linii_eng_updated.pdf"
      : "edinaja_gorjachaja_linija.pdf"

  return (
    <>
      <Heading className={styles.Title} like="h3">
        <Raw html={t("contacts.PopupHotLine.Title")} />
      </Heading>

      <div
        className={styles.Description}
        dangerouslySetInnerHTML={{ __html: t("contacts.PopupHotLine.Description") }}
      />

      <Tabs
        className={styles.Tabs}
        likeSelect="LgMax"
        placeholder={t("contacts.PopupHotLine.Select.Placeholder")}
      >
        {selectOptions.map((item, i) => {
          if (i === 0) {
            return (
              <Tab key={item} id={item} title={item}>
                <SendEmail />
              </Tab>
            )
          }

          if (i === 1) {
            return (
              <Tab key={item} id={item} title={item}>
                <Feedback />
              </Tab>
            )
          }

          if (i === 2) {
            return (
              <Tab key={item} id={item} title={item}>
                <CallOnPhone />
              </Tab>
            )
          }

          if (i === 3) {
            return (
              <Tab key={item} id={item} title={item}>
                <SendMail />
              </Tab>
            )
          }
        })}
      </Tabs>

      <div
        className={styles.Footer}
        dangerouslySetInnerHTML={{
          __html: t("contacts.PopupHotLine.Footer", { fileName }),
        }}
      />
    </>
  )
}

export const HotLine: React.FC<{ name: string }> = ({ name }) => (
  <PopupWrapper button={<div>{name}</div>} content={() => <Content />} />
)
