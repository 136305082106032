import cn from "classnames"
import React from "react"

import { Heading } from "~/components/Heading/Heading"
import { useThemeContext } from "~/components/Theme/Theme"

import styles from "./index.module.css"

type Props = {
  heading?: string
  title?: string
  className?: string
  link?: string
  hasHover?: boolean
  onClick?: () => void
  rel?: string
  target?: string
}

export const Card: React.FC<Props> = ({
  heading,
  title,
  children,
  className,
  link,
  hasHover,
  onClick,
  rel,
  target,
}) => {
  const { theme } = useThemeContext()
  const fullClassName = cn(className, styles.Wrapper, {
    [styles.OnDark]: theme === "dark",
    [styles.OnLight]: theme === "light",
    [styles.HasHover]: link || hasHover,
  })

  return (
    <div className={fullClassName} onClick={onClick}>
      {link && <a className={styles.Link} href={link} rel={rel} target={target} />}
      {heading && (
        <Heading className={styles.Heading} like="h5">
          {heading}
        </Heading>
      )}
      {title && (
        <Heading className={styles.Title} like="h2">
          {title}
        </Heading>
      )}

      {children}
    </div>
  )
}
