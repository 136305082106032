import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import { resources } from "./resources"

export type Lang = "ru" | "en"

export const detectLang = (ctxHost?: string): Lang => {
  if (process.env.FORCE_LANGUAGE) {
    return process.env.FORCE_LANGUAGE as Lang
  }

  const host = `${ctxHost ? ctxHost : location.host}`
  const lang = host.includes("sistema.com") ? "en" : "ru"

  return lang
}

export default (lng: Lang): void => {
  i18next.use(initReactI18next).init({
    lng,
    resources,
    fallbackLng: "ru",
    returnEmptyString: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
}
