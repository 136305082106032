import { useCallback, useState } from "react"

export const useSetState = <T>(initialState: T): [T, (newState: Partial<T>) => void] => {
  const [state, setState] = useState(initialState)

  const setMergeState = useCallback(
    (newState) => setState((oldState) => ({ ...oldState, ...newState })),
    [],
  )

  return [state, setMergeState]
}
