import cn from "classnames"
import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { HotLine } from "~/components/PopupHotLine/PopupHotLine"

import SearchSvg from "./assets/search.react.svg"
import styles from "./index.module.css"

export const Navigation: React.FC<{
  className?: string
  theme?: string
  onSearchClick: () => void
}> = ({ className, theme, onSearchClick }) => {
  const { t } = useTranslation()
  const items = [
    // { name: t("page.about"), link: "/about" },
    // { name: t("page.assets"), link: "/assets" },
    // { name: t("page.funds"), link: "/funds" },
    { name: t("page.investors-and-shareholders"), link: "/investors-and-shareholders" },
    // { name: t("page.press"), link: "/press" },
    { name: t("page.sustainable-development"), link: "/sustainable-development" },
    { name: t("page.procurements"), link: "/procurements" },
    { name: t("page.hotline"), component: () => <HotLine name={t("page.hotline")} /> },
    { name: t("page.contacts"), link: "/about/contacts" },
  ]

  const currentLang = i18next.language
  const routes = currentLang === "en" ? items.filter((val) => val.link !== "/procurements") : items
  // const routes = currentLang === "en" ? items.filter((val) => val.link !== "/contacts") : items

  return (
    <nav className={cn(className, styles.Wrapper)}>
      {/*<div*/}
      {/*  className={cn(styles.Search, {*/}
      {/*    [styles.SearchOnDark]: theme === "onDark",*/}
      {/*    [styles.SearchOnLight]: theme === "onLight",*/}
      {/*  })}*/}
      {/*  onClick={onSearchClick}*/}
      {/*>*/}
      {/*  <SearchSvg />*/}
      {/*</div>*/}

      <div className={styles.Links}>
        {routes.map(({ name, link, component }) => (
          <div key={name} className={styles.Link}>
            {component ? component() : <a href={link}>{name}</a>}
          </div>
        ))}
      </div>
    </nav>
  )
}
