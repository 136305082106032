import React from "react"
import { useTranslation } from "react-i18next"

import { policyFileName } from "~/components/PolicyFile/PolicyFile"
import { Raw } from "~/components/raw"
import { SocialLinks } from "~/components/Social/SocialLinks/SocialLinks"

import styles from "./index.module.css"

export const Bottom: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Block}>
        <div
          dangerouslySetInnerHTML={{ __html: t("footer.Address.Title") }}
          className={styles.Title}
        />
        <div dangerouslySetInnerHTML={{ __html: t("footer.Address.Content") }} />
      </div>
      <div className={styles.Block}>
        <div
          dangerouslySetInnerHTML={{ __html: t("footer.Helpdesk.Title") }}
          className={styles.Title}
        />
        <div>
          <a href="tel:+74957370101">+7 (495) 737-01-01</a>
        </div>
      </div>
      <div className={styles.Block}>
        <div className={styles.TitleEmpty}>&nbsp;</div>
        <SocialLinks axis="x" className={styles.Social} />
      </div>
      <div className={styles.Block}>
        <div className={styles.TitleEmpty}>&nbsp;</div>
        <div className={styles.Links}>
          <div className={styles.Link}>
            <a href={`/upload/${policyFileName()}`}>
              <Raw html={t("footer.Policy.Title")} />
            </a>
          </div>
          <div className={styles.Link}>
            <a href="mailto:kolchugin@sistema.ru,reshetnyak@sistema.ru,afknoreply@dabb.ru">
              <Raw html={t("footer.Bugreport.Title")} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
