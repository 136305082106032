/* eslint-disable complexity */
import { useEffect, useState } from "react"

import { useApiContext, useApiContextSlug } from "~/api"

type State = {
  lang: string
  host: string
  origin: string
  href: string
  oppositeHref: string
}[]

export const useLangLocation = (language: "en" | "ru" | string): any => {
  const [hostsArray, setState] = useState<State>([])
  const slug = useApiContextSlug()
  const news = useApiContext("/news/element")
  const programs = useApiContext("/programs/element")

  useEffect(() => {
    const { protocol, pathname: currentPathname, host: currentHost } = location
    let defaultPathname = currentPathname
    let pathnameRu
    let pathnameEn

    if (currentPathname.includes("/ir-blog")) {
      defaultPathname = "/investors-and-shareholders"
    }

    if (currentPathname.includes("/press/media/corporate-magazine")) {
      defaultPathname = "/press"
    }

    if (
      currentPathname.startsWith("/investors-and-shareholders/disclosure/") &&
      !currentPathname.includes("yearreports")
    ) {
      defaultPathname = "/investors-and-shareholders/disclosure"
    }

    if (currentPathname.startsWith("/press/")) {
      const { codeRu, codeEn } = news

      pathnameRu = codeRu ? `/press/${slug}/${codeRu}` : "/press"
      pathnameEn = codeEn ? `/press/${slug}/${codeEn}` : "/press"
    }

    if (currentPathname.startsWith("/about/career/")) {
      const { codeRu, codeEn } = programs

      pathnameRu = codeRu ? `/about/career/${codeRu}` : "/about/career"
      pathnameEn = codeEn ? `/about/career/${codeEn}` : "/about/career"
    }

    if (currentPathname.startsWith("/sustainable-development/programs/")) {
      const { codeRu, codeEn } = programs

      pathnameRu = codeRu
        ? `/sustainable-development/programs/${codeRu}`
        : "/sustainable-development/programs"
      pathnameEn = codeEn
        ? `/sustainable-development/programs/${codeEn}`
        : "/sustainable-development/programs"
    }

    const hosts = [
      {
        lang: "en",
        host: "sistema.com",
        pathname: pathnameEn || defaultPathname,
      },
      {
        lang: "ru",
        host: "sistema.ru",
        pathname: pathnameRu || defaultPathname,
      },
    ]

    const findOpposite = (arr: any[], lang: string): any => {
      return arr.find((host) => host.lang !== lang)
    }

    const hostsArray = hosts.map(({ lang, host, pathname }, i, arr) => {
      const oppositeHost = findOpposite(arr, lang)?.host as string
      const oppositePathname = findOpposite(arr, lang)?.pathname as string

      const newHost = currentHost.replace(host, oppositeHost)
      const langHost = currentHost.replace(oppositeHost, host)

      const newPathname = defaultPathname.replace(pathname, oppositePathname)
      const langPathname = defaultPathname.replace(oppositePathname, pathname)

      return {
        lang,
        host: langHost,
        origin: `${protocol}//${langHost}`,
        href: `${protocol}//${langHost}${langPathname}`,
        oppositeHref: `${protocol}//${newHost}${newPathname}`,
      }
    })

    setState(hostsArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return hostsArray.find(({ lang }) => lang === language) || {}
}
