import React from "react"
import { useTranslation } from "react-i18next"

import { TabWrapper } from "../TabWrapper/TabWrapper"
import styles from "./index.module.css"

export const CallOnPhone: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TabWrapper title={t("contacts.PopupHotLine.CallOnPhone.Title")}>
      <a className={styles.Link} href="tel:+74952281502">
        +7 (495) 228-15-02
      </a>
    </TabWrapper>
  )
}
