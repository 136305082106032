type postFetchType = (
  url: string,
  options?: RequestInit,
) => Promise<{
  error: any
}>

export const postFetch: postFetchType = async (url, options = { method: "POST" }) => {
  const response = await fetch(url, {
    // headers: { "Content-Type": "multipart/form-data" },
    // credentials: "include",
    ...options,
  }).catch((error) => error)

  try {
    return {
      error: response.status !== 200,
    }
  } catch (error) {
    return { error }
  }
}
