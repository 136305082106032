import React from "react"
import { useTranslation } from "react-i18next"

import { Heading } from "~/components/Heading/Heading"
import { Raw } from "~/components/raw"

import styles from "./index.module.css"

type Props = {
  message?: { title: string; message: string }
}

export const SuccessPopup: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.Wrapper}>
      <Heading className={styles.Title} like="h3">
        <Raw html={message ? message.title : t("popups.Success.Title")} />
      </Heading>

      <div
        dangerouslySetInnerHTML={{ __html: message ? message.message : t("popups.Success.Text") }}
      />
    </div>
  )
}
