import cn from "classnames"
import React from "react"

// import Instagram from "../assets/instagram.react.svg"
import Telegram from "../assets/telegram.react.svg"
import Twitter from "../assets/twitter.react.svg"
import Youtube from "../assets/youtube.react.svg"
import styles from "./index.module.css"

type SocialShareProps = {
  className?: string
  axis: "x" | "y"
}

export const SocialLinks: React.FC<SocialShareProps> = ({ className, axis }) => (
  <div
    className={cn(className, {
      [styles.AxisX]: axis === "x",
      [styles.AxisY]: axis === "y",
    })}
  >
    {/* hidden by #5 */}
    {/*<a*/}
    {/*  className={styles.Item}*/}
    {/*  href="https://twitter.com/Sistema_PJSFC"*/}
    {/*  rel="noopener noreferrer"*/}
    {/*  target="_blank"*/}
    {/*>*/}
    {/*  <Twitter />*/}
    {/*</a>*/}

    <a
      className={styles.Item}
      href="https://t.me/sistema_news"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Telegram />
    </a>

    <a
      className={styles.Item}
      href="https://www.youtube.com/channel/UCnK6zczQreWoqfMa4gEa1_g/videos"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Youtube />
    </a>

    {/* <a
      className={styles.Item}
      href="https://www.instagram.com/afk_sistema/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Instagram />
    </a> */}
  </div>
)
