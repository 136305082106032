import cn from "classnames"
import React from "react"

import { CheckVisibility } from "~/components/CheckVisibility/CheckVisibility"
import { Heading } from "~/components/Heading/Heading"
import { Theme } from "~/components/Theme/Theme"
import useMenuItems from "~/helpers/useMenuRoutes"

import { Bottom } from "./components/Bottom/Bottom"
import styles from "./index.module.css"

export const Footer: React.FC<{ isHome?: boolean }> = ({ isHome }) => {
  const menuItems = useMenuItems()

  return (
    <Theme as="footer" className={styles.Wrapper} theme="dark">
      <CheckVisibility>
        {() => (
          <div className={cn(styles.Inner, { [styles.InHome]: isHome })}>
            <div className={styles.Top}>
              {menuItems.map(({ name, link, items }) => (
                <div key={name} className={styles.Block}>
                  <Heading className={styles.Title} like="h4">
                    {link ? <a href={link}>{name}</a> : <p>{name}</p>}
                  </Heading>

                  {items && items.length > 0 && (
                    <div className={styles.List}>
                      {items.map(({ name, component, link }) => {
                        if (!component) {
                          return (
                            <div key={name} className={styles.Link}>
                              <a href={link}>{name}</a>
                            </div>
                          )
                        }

                        if (!!component) {
                          return (
                            <div key={name} className={styles.Link}>
                              {component}
                            </div>
                          )
                        }
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <Bottom />
          </div>
        )}
      </CheckVisibility>
    </Theme>
  )
}
