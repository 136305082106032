import React from "react"
import { CSSTransition } from "react-transition-group"

import useMenuItems from "~/helpers/useMenuRoutes"

import { Bottom } from "./components/Bottom/Bottom"
import styles from "./index.module.css"

export const Menu: React.FC<{ isOpen: boolean; isSearchOpen: boolean }> = ({
  isOpen,
  isSearchOpen,
}) => {
  return (
    <CSSTransition
      classNames={{
        enter: styles.Enter,
        enterActive: styles.EnterActive,
        exit: styles.Exit,
        exitActive: styles.ExitActive,
      }}
      in={isOpen}
      timeout={500}
      unmountOnExit
    >
      <div className={styles.Wrapper}>
        <div className={styles.Inner}>
          <div className={styles.Top}>
            <div className={styles.TopInner}>
              {useMenuItems().map(({ link, name, items }) => (
                <div key={name} className={styles.Block}>
                  <div className={styles.Title}>
                    <a href={link}>{name}</a>
                  </div>

                  {items && items.length > 0 && (
                    <div className={styles.List}>
                      {items.map(({ name, component, link }) => {
                        if (!component) {
                          return (
                            <div key={name} className={styles.Link}>
                              <a href={link}>{name}</a>
                            </div>
                          )
                        }

                        if (!!component) {
                          return (
                            <div key={name} className={styles.Link}>
                              {component}
                            </div>
                          )
                        }
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Bottom />
        </div>
      </div>
    </CSSTransition>
  )
}
