import React from "react"
import { useTranslation } from "react-i18next"

import { Link } from "~/components/Link/Link"

import { TabWrapper } from "../TabWrapper/TabWrapper"

export const SendEmail: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TabWrapper title={t("contacts.PopupHotLine.SendEMail.Title")}>
      <Link href="mailto:report@sistema.ru" view="mail">
        report@sistema.ru
      </Link>
    </TabWrapper>
  )
}
