import cn from "classnames"
import React from "react"

import { FadeTransition } from "../Transitions/Transitions"
import styles from "./index.module.css"

type Props = {
  isOpen: boolean
  className?: string
}

export const Popover: React.FC<Props> = ({ children, isOpen, className }) => {
  return (
    <FadeTransition isVisible={isOpen}>
      <div className={cn(styles.Wrapper, className)}>{children}</div>
    </FadeTransition>
  )
}
