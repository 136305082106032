/* eslint-disable complexity */
import cn from "classnames"
import React, { useState } from "react"

import { RequestType } from "~/api"
import { Button } from "~/components/Button/Button"
import { Select } from "~/components/Select/Select"

import styles from "./index.module.css"

type TabType = { className?: string; count?: number; id: string; title: string }

export const Tab: React.FC<TabType> = ({ children }) => <>{children}</>

type TabsType = {
  likeSelect?: "XsMax" | "LgMax"
  isTabSimple?: boolean
  isTabThin?: boolean
  selectedTabId?: string
  placeholder?: string
  className?: string
  request?: RequestType
  queryKey?: string
}

export const Tabs: React.FC<TabsType> = ({
  likeSelect,
  isTabSimple,
  isTabThin,
  selectedTabId,
  children,
  className,
  placeholder,
  request,
  queryKey,
}) => {
  const isRequestEmpty = request && Object.keys(request?.query).length === 0
  const isValidQueryKey = queryKey && Object.keys(request?.query)[0] === queryKey

  const tabProps = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }
    return child.props
  }) as any[]

  if (request && !isRequestEmpty && isValidQueryKey) {
    const {
      query: { ...query },
    } = request

    const queryId = Object.values(query)[0] as string

    const isValidId = tabProps.some((value) => {
      return value.id === queryId
    })

    selectedTabId = isValidId ? queryId : selectedTabId
  }

  const [selectedTab, setSelectedTab] = useState(selectedTabId)

  const onTabSelect = (id: string) => () => {
    setSelectedTab(id)

    if (request && !isRequestEmpty && isValidQueryKey) {
      history.replaceState(null, "", `${location.pathname}?${queryKey}=${id}`)
    }
  }

  const renderChildren = (): void => {
    const currentTab = tabProps.find(({ id }) => id === selectedTab)

    if (selectedTab && currentTab) {
      return tabProps.find(({ id }) => id === selectedTab).children
    }

    if (selectedTab && !currentTab) {
      setSelectedTab(selectedTabId)
      return tabProps.find(({ id }) => id === selectedTabId).children
    }
  }

  return (
    <React.Fragment>
      <div className={className}>
        {likeSelect !== "LgMax" && (
          <div
            className={cn({
              ["d-none d-sm-block"]: likeSelect === "XsMax",
            })}
          >
            <div className={styles.Tabs}>
              {tabProps.map(({ id, title, count, className }) => {
                if (!isTabSimple && !isTabThin) {
                  return (
                    <Button
                      key={id}
                      className={cn(styles.Tab, className, {
                        [styles.TabActive]: id === selectedTab,
                      })}
                      view="rounded"
                      onClick={onTabSelect(id)}
                    >
                      <div>{title}</div>
                      {count !== undefined && <div className={styles.Count}>{count}</div>}
                    </Button>
                  )
                }

                if (isTabSimple) {
                  return (
                    <div
                      key={id}
                      className={cn(styles.TabSimple, className, {
                        [styles.TabSimpleActive]: id === selectedTab,
                      })}
                      dangerouslySetInnerHTML={{ __html: title }}
                      onClick={onTabSelect(id)}
                    />
                  )
                }

                if (isTabThin) {
                  return (
                    <div
                      key={id}
                      className={cn(styles.TabThin, className, {
                        [styles.TabThinActive]: id === selectedTab,
                      })}
                      dangerouslySetInnerHTML={{ __html: title }}
                      onClick={onTabSelect(id)}
                    />
                  )
                }
              })}
            </div>
          </div>
        )}

        {likeSelect && (
          <div
            className={cn({
              ["d-sm-none"]: likeSelect === "XsMax",
            })}
          >
            <Select
              options={tabProps.map(({ id, title }) => ({ label: title, value: id }))}
              placeholder={placeholder}
              onChange={({ value }) => onTabSelect(value)()}
            />
          </div>
        )}
      </div>

      {renderChildren()}
    </React.Fragment>
  )
}
