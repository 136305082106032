import { RefObject, useEffect } from "react"

const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (e: MouseEvent) => void,
): void => {
  const handleClickOutside = (e: MouseEvent): void => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback(e)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}

export default useOutsideClick
