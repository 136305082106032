import "../../assets/styles/index.css"

import React from "react"

import { Footer } from "~/components/Footer/Footer"
import Head from "~/components/head"
import { Header, HeaderType } from "~/components/Header/Header"
import { LayoutBg } from "~/components/LayoutBg/LayoutBg"

import { Cookie } from "../Cookie/Cookie"
import { SocialLinks } from "../Social/SocialLinks/SocialLinks"
import styles from "./index.module.css"

type LayoutTypes = {
  headerTheme?: HeaderType["theme"]
  title: string
  description?: string
  isHome?: boolean
  ogImage?: string | null
}

export const Layout: React.FC<LayoutTypes> = ({
  children,
  headerTheme,
  title,
  description,
  isHome,
  ogImage,
}) => (
  <React.Fragment>
    <Head description={description} image={ogImage} isHome={isHome} title={title} />
    {/*<SocialLinks axis="y" className={styles.SocialLinks} />*/}
    <Cookie />
    <div className={styles.Root}>
      <Header isHome={isHome} theme={headerTheme} />
      <div className={styles.Wrapper}>{children}</div>
      {isHome && <LayoutBg />}
      <Footer isHome={isHome} />
    </div>
  </React.Fragment>
)
