import cn from "classnames"
import React, { useContext } from "react"

import styles from "./index.module.css"

type ThemeContextType = {
  theme: "light" | "dark" | "light-blue"
}

const ThemeContext = React.createContext<ThemeContextType>({
  theme: "light",
})

type ThemeProps = {
  className?: string
  as?: React.ElementType<any>
} & ThemeContextType

export const Theme: React.FC<ThemeProps> = ({
  className,
  children,
  theme,
  as: Component = "div",
}) => {
  const clsName = cn(className, {
    [styles.Light]: theme === "light",
    [styles.Dark]: theme === "dark",
  })

  return (
    <ThemeContext.Provider value={{ theme }}>
      <Component className={clsName}>{children}</Component>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = (): ThemeContextType => {
  return useContext(ThemeContext)
}
