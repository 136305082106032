import React, { FC } from "react"

/**
 * * Обёртка для вставки контента из /api
 *
 * ! Компонент намеренно не содержит вохможности кастомизировать span
 */
export const Raw: FC<{ html: string }> = ({ html }) => {
  return <span dangerouslySetInnerHTML={{ __html: html }} />
}
