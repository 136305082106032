/* eslint-disable complexity */
import i18next from "i18next"
import NextHead from "next/head"
import React, { useEffect } from "react"

import ogImageEn from "../assets/images/og-sistema-com.jpg"
import ogImageRu from "../assets/images/og-sistema-ru.jpg"

type Props = {
  title: string
  description?: string
  isHome?: boolean
  image?: string | null
}

const Head: React.FC<Props> = ({ title, description, isHome, image }) => {
  const currentLang = i18next.language
  const url = currentLang === "ru" ? "https://sistema.ru" : "https://sistema.com"
  const metaTitleTemplate = ` — ${currentLang === "ru" ? "АФК «Система»" : "Sistema"}`
  const defaultTitle = title + metaTitleTemplate
  const customTitle = isHome ? title : defaultTitle
  const ogImage = image ?? (currentLang === "ru" ? ogImageRu : ogImageEn)

  useEffect(() => {
    // TODO: в последствии можно удалить. Сейчас проблем с отображением правильного title не наблюдается.
    // const parser = new DOMParser()
    // const parsedTitle = parser.parseFromString(title, "text/html").body.innerHTML
    // document.querySelector("title")!.innerHTML = parsedTitle + metaTitleTemplate

    const windowWidth =
      window.outerWidth < window.screen.width ? window.outerWidth : window.screen.width
    const minWidth = 414
    if (windowWidth >= minWidth) {
      return
    }
    const ratio = windowWidth / minWidth

    document
      .querySelector('[name="viewport"]')!
      .setAttribute("content", `width=device-width, initial-scale=${ratio}`)
  })

  return (
    <NextHead>
      <title>{customTitle}</title>
      <link href="/favicon.png" rel="shortcut icon" />
      <link href="/favicon.png" rel="icon" type="image/png" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta
        content={currentLang === "ru" ? "0537be90cd8315e0" : "2b9e490a11172cb2"}
        name="yandex-verification"
      />
      <meta content="50fc07bb61fcf2a8415b6c5687f46b5a" name="cmsmagazine" />

      {/* <!-— facebook open graph tags --> */}
      <meta content="website" property="og:type" />
      <meta content="Sistema" property="og:site_name" />
      <meta content={customTitle} property="og:title" />
      <meta content={description} property="og:description" />
      <meta content={url + ogImage} property="og:image" />
      <meta content="1200" property="og:image:width" />
      <meta content="630" property="og:image:height" />
      {/* <meta content={url} property="og:url" /> */}

      {/* <!-— twitter card tags additive with the og: tags --> */}
      <meta content={customTitle} name="twitter:title" />
      <meta content={description} name="twitter:description" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={url + ogImage} name="twitter:image" />
      {/* <meta content="@twitterNickName" name="twitter:site" /> */}

      {/* <!-- Google Tag Manager --> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NWJRZ6R');`,
        }}
      />
      {/* <!-- End Google Tag Manager --> */}
    </NextHead>
  )
}

export default Head
