import { useEffect } from "react"

const getScrollbarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth
}

export const useFixedPosition = (closeHandler: (e: Event) => void, condition?: boolean): void => {
  useEffect(() => {
    if (condition !== undefined && !condition) {
      return
    }

    const scrollBarWidth = getScrollbarWidth()
    const ua = window.navigator.userAgent
    const isDesktopSafari =
      !/mobile/i.test(ua) && /version\/([\w.]+).+?(mobile\s?safari|safari)/i.test(ua)

    const escapeKeyListener = (e: KeyboardEvent): void => {
      if (!closeHandler) {
        return
      }
      if (e.keyCode === 27) {
        closeHandler(e)
      }
    }

    if (isDesktopSafari) {
      document.body.style.overflow = "hidden"
    }

    if (!isDesktopSafari) {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = "fixed"
      document.body.style.width = `calc(100% - ${scrollBarWidth}px)`
      document.body.style.setProperty("--scrollBarWidth", `${scrollBarWidth}px`)
    }

    document.addEventListener("keydown", escapeKeyListener)

    return () => {
      if (isDesktopSafari) {
        document.body.style.overflow = ""
      }

      if (!isDesktopSafari) {
        const scrollY = document.body.style.top

        document.body.style.top = ""
        document.body.style.position = ""
        document.body.style.width = ""
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1)
        document.body.style.setProperty("--scrollBarWidth", "0")
      }

      document.removeEventListener("keydown", escapeKeyListener)
    }
  }, [condition])
}
