import React from "react"
import { CSSTransition } from "react-transition-group"

import styles from "./index.module.css"

type Props = {
  isVisible: boolean
  unmountOnExit?: boolean
  timeout?: number
}

export const FadeTransition: React.FC<Props> = ({
  children,
  isVisible,
  unmountOnExit = true,
  timeout = 300,
}) => (
  <CSSTransition
    classNames={{
      enter: styles.FadeEnter,
      enterActive: styles.FadeEnterActive,
      exitActive: styles.FadeExitActive,
      exitDone: styles.FadeExitActive,
    }}
    in={isVisible}
    timeout={timeout}
    unmountOnExit={unmountOnExit}
  >
    <>{children}</>
  </CSSTransition>
)
