import i18next from "i18next"
import React from "react"

import { HotLine } from "~/components/PopupHotLine/PopupHotLine"
import { useLangLocation } from "~/helpers/useLangLocation"

type UseMenuRoutesProps = {
  name?: string
  link?: string
  items?: Items[]
}

type Items = {
  name?: string
  link?: string
  component?: JSX.Element
}

const useMenuRoutes = (): UseMenuRoutesProps[] => {
  const { origin } = useLangLocation("en")

  return [
    {
      name: i18next.t("page.about"),
      // link: "/about",
      items: [
        // { name: i18next.t("page.investment-strategy"), link: "/about/investment-strategy" },
        // { name: i18next.t("page.history"), link: "/about/history" },
        // { name: i18next.t("page.team"), link: "/about/team" },
        // { name: i18next.t("page.career"), link: "/about/career" },
        { name: i18next.t("page.corpmanage"), link: "/about/corpmanage" },
        { name: i18next.t("page.corpdocs"), link: "/about/corpmanage/docs" },
        {
          name: i18next.t("page.hotline"),
          component: <HotLine name={i18next.t("page.hotline")} />,
        },
        { name: i18next.t("page.contacts"), link: "/about/contacts" },
      ],
    },
    // {
    //   name: i18next.t("page.assets"),
    //   link: "/assets",
    //   items: [
    //     // { name: i18next.t("page.projects"), link: "#!" }
    //   ],
    // },

    // {
    //   name: i18next.t("page.funds"),
    //   link: "/funds",
    //   items: [
    //     // { name: i18next.t("page.ideas"), link: "#!" }
    //   ],
    // },
    // {
    //   name: i18next.t("page.press"),
    //   link: "/press",
    //   items: [
    //     { name: i18next.t("page.pressreleases"), link: "/press/pressreleases" },
    //     { name: i18next.t("page.news"), link: "/press/news" },
    //     { name: i18next.t("page.media"), link: "/press/media" },
    //     { name: i18next.t("page.massmedia"), link: "/press/massmedia" },
    //     { name: i18next.t("page.corporate-magazine"), link: "/press/media/corporate-magazine" },
    //   ],
    // },
    {
      name: i18next.t("page.investors-and-shareholders"),
      link: "/investors-and-shareholders",
      items: [
        {
          name: i18next.t("page.financial"),
          link: "/investors-and-shareholders/financial",
        },
        // {
        //   name: i18next.t("page.reports"),
        //   link: "/investors-and-shareholders/disclosure/yearreports",
        // },
        { name: i18next.t("page.stock"), link: "/investors-and-shareholders/stock" },
        { name: i18next.t("page.obligation"), link: "/investors-and-shareholders/obligation" },
        // { name: i18next.t("page.ratings"), link: "/investors-and-shareholders/ratings" },
        // { name: i18next.t("page.analysts"), link: "/investors-and-shareholders/analytics" },
        { name: i18next.t("page.calendar"), link: "/investors-and-shareholders/calendar" },
        // {
        //   name: i18next.t("page.ir-committee"),
        //   link: "/investors-and-shareholders/committee-investor-relations",
        // },
        // { name: i18next.t("page.ir-blog"), link: `${origin}/investors-and-shareholders/ir-blog` },
        {
          name: i18next.t("page.stockholders-meeting"),
          link: "/investors-and-shareholders/stockholders-meeting",
        },
        { name: i18next.t("page.faq"), link: "/investors-and-shareholders/faq" },
        { name: i18next.t("page.disclosure"), link: "/investors-and-shareholders/disclosure" },
      ],
    },
    {
      name: i18next.t("page.sustainable-development"),
      link: "/sustainable-development",
      items: [
        {
          name: i18next.t("page.ethics"),
          link: "/sustainable-development/responsible-investment-principles",
        },
        {
          name: i18next.t("page.non-financial-reporting"),
          link: "/sustainable-development/non-financial-reporting",
        },
        {
          name: i18next.t("page.indexes-and-ratings"),
          link: "/sustainable-development/indexes-and-ratings",
        },
        // {
        //   name: i18next.t("page.newssocial"),
        //   link: "/press/news?page_number=1&tags_arr=66231",
        // },
        // { name: i18next.t("page.development-cases"), link: "#!" },
      ],
    },
    {
      name: i18next.t("page.procurements"),
      link: "/procurements",
      items: [
        {
          name: i18next.t("page.conflict-commission"),
          link: "/procurements/conflict-commission",
        },
      ],
    },
  ]
}

const excludedItemsEn = [
  "/procurements",
  "/sustainable-development/newssocial",
  "/press/media/corporate-magazine",
  "/press/news?page_number=1&tags_arr=66231",
]

const groupRoutes = (routes: any[]): any => {
  if (Array.isArray(routes)) {
    return routes.reduce((prev, current) => {
      if (!excludedItemsEn.includes(current.link)) {
        current.items = groupRoutes(current.items)
        prev.push(current)
      }

      return prev
    }, [])
  }
}

const useMenuItems = (): UseMenuRoutesProps[] => {
  const routes = useMenuRoutes()
  const currentLang = i18next.language

  return currentLang === "en" ? groupRoutes(routes) : routes
}

export default useMenuItems
