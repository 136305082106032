import ReactDOM from "react-dom"

type PortalProps = {
  el?: string
}

export const Portal: React.FC<PortalProps> = ({ el = "#modal", children }) => {
  if (!el) {
    return null
  }

  return ReactDOM.createPortal(children, document.querySelector(el) as HTMLElement)
}
