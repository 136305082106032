import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CSSTransition } from "react-transition-group"

import { Button } from "../Button/Button"
import { Raw } from "../raw"
import styles from "./index.module.css"

export const Cookie: React.FC = () => {
  const [showCookies, setShowCookies] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setShowCookies(!localStorage.getItem("cookies"))
  }, [showCookies])

  const clickHandler = (): void => {
    localStorage.setItem("cookies", "true")
    setShowCookies(false)
  }
  return (
    <CSSTransition
      classNames={{
        enter: styles.WrapperEnter,
        exitActive: styles.WrapperExitActive,
      }}
      in={showCookies}
      timeout={1000}
      unmountOnExit
    >
      <div className={styles.Wrapper}>
        <div className="container">
          <div className={styles.Inner}>
            <div className={styles.Text} dangerouslySetInnerHTML={{ __html: t("Cookie.Text") }} />

            <Button view="xs" onClick={clickHandler}>
              <Raw html={t("Cookie.Button")} />
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
